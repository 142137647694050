<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat" style="margin-top: 10px;">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>时间：</span>
                </div>
                <div class="baseSearchOneRight2 clearfloat">
                    <el-date-picker v-model="searchForm.time" type="daterange" unlink-panels
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="YYYY-MM-DD"></el-date-picker>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>项目名称：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.project_name" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne tableButton" style="float: left;">
                <el-tag size="large">支付笔数：{{total_price.count}}</el-tag>
            </div>
            <div class="baseOperateOne" style="float: left;">
                <el-tag size="large">支付金额：{{total_price.pay_price}}元</el-tag>
            </div>
            <div class="baseOperateOne">
                <el-button type="primary" @click="openChangeTableTitle">更改表头</el-button>
            </div>
            <div class="baseOperateOne tableButton">
                <el-button type="primary" @click="openExport">导出全部</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="org_name" label="项目名称"></el-table-column>
                <template v-for="item in tableTitles" :key="item.key">
                    <el-table-column :prop="item.key" :label="item.name" v-if="item.show"></el-table-column>
                </template>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary" @click.prevent="openDetail(scope.row)">
                            查看详情
                        </el-link>
                        <!--<el-link class="tableButton" type="primary" @click.prevent="exportExcel(scope.row)">-->
                        <!--下载报表-->
                        <!--</el-link>-->
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="表头显示" v-model="dialogVisible" width="600px">
            <el-form label-width="100px">
                <el-form-item label="表头显示" prop="name">
                    <el-checkbox v-for="item in tableTitles" v-model="item.show" :label="item.name" :key="item.key"
                                 @change="titlesChange"></el-checkbox>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-drawer title="详情" v-model="drawer" :close-on-click-modal="false" :destroy-on-close="true" size="80%">
            <div class="rtDetailSearch baseSearch clearfloat">
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>组织部门：</span>
                    </div>
                    <div class="baseSearchOneRight">
                        <el-cascader v-model="detailSearchForm.org_id" :options="class_id_list"
                                     :props="props" clearable></el-cascader>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <div class="baseSearchOneLeft">
                        <span>姓名：</span>
                    </div>
                    <div class="baseSearchOneRight">
                        <el-input v-model="detailSearchForm.user_name" placeholder="请输入内容" clearable></el-input>
                    </div>
                </div>
                <div class="baseSearchOne">
                    <el-button type="primary" style="margin-left: 20px;" @click="detailSearch">搜索</el-button>
                    <el-button @click="detailCleanAll">清空</el-button>
                </div>
            </div>
            <div class="rtDetailOperate baseOperate clearfloat">
                <div class="baseOperateOne tableButton" style="float: left;">
                    <el-tag size="large">应缴金额：{{detail_total_price.price}}</el-tag>
                </div>
                <div class="baseOperateOne" style="float: left;">
                    <el-tag size="large">待缴金额：{{detail_total_price.be_received_price}}</el-tag>
                </div>
                <div class="baseOperateOne">
                    <el-button type="primary" @click="openDetailExport">导出全部</el-button>
                </div>
            </div>
            <div class="baseTable">
                <publicTable :publicTableOtherHeight="(detailSearchHeight+16)" :publicTableData="detailTableData">
                    <el-table-column prop="project_name" label="缴费项目"></el-table-column>
                    <el-table-column prop="card_number" label="学工号"></el-table-column>
                    <el-table-column prop="user_name" label="姓名"></el-table-column>
                    <el-table-column prop="class_name" label="组织部门"></el-table-column>
                    <el-table-column prop="received_price" label="已缴金额"></el-table-column>
                    <el-table-column prop="be_received_price" label="待缴金额"></el-table-column>
                    <el-table-column prop="refund_price" label="退款金额"></el-table-column>
                </publicTable>
            </div>
            <div class="basePage" style="margin-top: 4px;">
                <publicPage ref="detailPageUserStudent" :publicPageTotal="{total:detailTotal}"
                            @pageChange="getDetailTableData"></publicPage>
            </div>
        </el-drawer>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx'

    import {businessGet} from '../../api/business.js'

    import {utilsExportExcel} from '../../utils/utils.js'

    export default {
        name: "payProjectReport",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                searchForm: {
                    time: [],
                    project_name: '',
                },
                searchHeight: 0,
                total_price: {
                    count: 0,
                    pay_price: 0
                },
                tableTitles: [
                    {
                        name: '应缴人数',
                        key: 'yingjiao_count',
                        show: false
                    },
                    {
                        name: '应缴金额',
                        key: 'yingjiao_price',
                        show: false
                    },
                    // {
                    //     name: '已缴人数',
                    //     key: 'a4',
                    //     show: false
                    // },
                    // {
                    //     name: '已缴金额',
                    //     key: 'a5',
                    //     show: false
                    // },
                    {
                        name: '待缴人数',
                        key: 'daijiao_count',
                        show: false
                    },
                    {
                        name: '待缴金额',
                        key: 'daijiao_price',
                        show: false
                    },
                    {
                        name: '微信支付笔数',
                        key: 'wechat_count',
                        show: false
                    },
                    {
                        name: '微信支付金额',
                        key: 'wechat_price',
                        show: false
                    },
                    // {
                    //     name: '支付宝支付笔数',
                    //     key: 'a10',
                    //     show: false
                    // },
                    // {
                    //     name: '支付宝支付金额',
                    //     key: 'a11',
                    //     show: false
                    // },
                    {
                        name: '现金支付笔数',
                        key: 'cash_count',
                        show: false
                    },
                    {
                        name: '现金支付金额',
                        key: 'cash_price',
                        show: false
                    },
                    {
                        name: '退款笔数',
                        key: 'refund_count',
                        show: false
                    },
                    {
                        name: '退款金额',
                        key: 'refund_price',
                        show: false
                    },
                    {
                        name: '实际入账',
                        key: 'profit',
                        show: false
                    },
                ],
                tableLoading: false,
                tableData: [],
                total: 0,
                dialogVisible: false,
                localName: 'fu_zst_payProjectReport_tableTitles',
                selMsg: null,
                drawer: false,
                class_id_list: [],
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'son',
                    checkStrictly: true
                },
                detailSearchForm: {
                    org_id: '',
                    user_name: ''
                },
                detailSearchHeight: 0,
                detail_total_price: {
                    be_received_price: 0,
                    price: 0
                },
                detailTableData: [],
                detailTotal: 0,
            }
        },
        created() {
            if (localStorage.getItem(this.localName)) {
                let oldShowTableTitles = JSON.parse(localStorage.getItem(this.localName));
                for (let i = 0; i < oldShowTableTitles.length; i++) {
                    this.tableTitles.filter((item, index) => {
                        if (oldShowTableTitles[i].key == item.key) {
                            this.tableTitles[index].show = true;
                        }
                    })
                }
            }
            else {
                this.tableTitles.forEach(item => {
                    item.show = true;
                })
                this.titlesChange();
            }
        },
        mounted() {
            this.getTreeData();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight + 10;
                this.searchHeight = searchHeight;
                this.cleanAll();
            })
        },
        methods: {
            openDetailExport() {
                let url = '/admin/v1/project_report_info?is_export=1&project_id=' + this.selMsg.id;
                for (let k in this.detailSearchForm) {
                    if (this.detailSearchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.detailSearchForm[k][this.detailSearchForm[k].length - 1];
                        }
                        else {
                            url = url + '&' + k + '=' + this.detailSearchForm[k];
                        }
                    }
                }
                utilsExportExcel(url)
            },
            detailExportExcel() {
                this.$confirm('此操作将下载文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            detailCleanAll() {
                this.detailSearchForm.org_id = '';
                this.detailSearchForm.user_name = '';
                this.detailSearch();
            },
            detailSearch() {
                this.$refs.detailPageUserStudent.currentPage = 1;
                this.getDetailTableData();
            },
            getDetailTableData() {
                let url = '/admin/v1/project_report_info?project_id=' + this.selMsg.id + '&page=' + this.$refs.detailPageUserStudent.currentPage + '&page_pre=' + this.$refs.detailPageUserStudent.pageSize;
                for (let k in this.detailSearchForm) {
                    if (this.detailSearchForm[k]) {
                        if (k == 'org_id') {
                            url = url + '&' + k + '=' + this.detailSearchForm[k][this.detailSearchForm[k].length - 1];
                        }
                        else {
                            url = url + '&' + k + '=' + this.detailSearchForm[k];
                        }
                    }
                }
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.detailTotal = res.data.data.count;
                        this.detailTableData = res.data.data.list;
                        if (res.data.data.total_price.be_received_price != null) {
                            this.detail_total_price.be_received_price = res.data.data.total_price.be_received_price;
                        }
                        if (res.data.data.total_price.price != null) {
                            this.detail_total_price.price = res.data.data.total_price.price;
                        }
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            openDetail(val) {
                this.selMsg = val;
                this.drawer = true;
                this.$nextTick(() => {
                    let baseSearchDom = document.querySelector('.rtDetailSearch');
                    let baseOperateDom = document.querySelector('.rtDetailOperate');
                    let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                    this.detailSearchHeight = searchHeight;
                    this.detailCleanAll();
                });
            },
            getTreeData() {
                let url = '/admin/v1/organization?type=1';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.class_id_list = res.data.data;
                    }
                })
            },
            openExport() {
                let url = '/admin/v1/project_report?is_export=1';
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                utilsExportExcel(url)
            },
            exportExcel() {
                this.$confirm('此操作将下载文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    //数据表格
                    let exportList = [];
                    var aoa = [
                        ['支付宝账号', '支付宝账号姓名', '转账金额', '转账人', '转账时间', '转账状态', '备注'],
                    ];
                    let dayinName = ['receiverId', 'receiverName', 'transAmount', 'operaterName', 'fuTransDate', 'statusName', 'remark']
                    exportList.forEach(item => {
                        let a = []
                        dayinName.forEach(item2 => {
                            a.push(item[item2])
                        })
                        aoa.push(a)
                    })
                    //创建book
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(aoa);
                    //设置列宽
                    ws['!cols'] = [
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                        {width: 20},
                    ];
                    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
                    //输出
                    let exportName = "转账记录.xlsx"
                    XLSX.writeFile(wb, exportName);
                }).catch(() => {
                })
            },
            titlesChange() {
                let newArray = [];
                this.tableTitles.forEach(item => {
                    if (item.show) {
                        newArray.push(item)
                    }
                })
                localStorage.setItem(this.localName, JSON.stringify(newArray));
            },
            openChangeTableTitle() {
                this.dialogVisible = true;
            },
            cleanAll() {
                this.searchForm.time = [];
                this.searchForm.project_name = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/project_report?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'time') {
                            if (this.searchForm.time[0] && this.searchForm.time[1]) {
                                url = url + '&' + k + '=' + this.searchForm.time[0] + ' 00:00:00 - ' + this.searchForm.time[1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.list;
                        if (res.data.data.order_total.count != null) {
                            this.total_price.count = res.data.data.order_total.count
                        }
                        if (res.data.data.order_total.pay_price != null) {
                            this.total_price.pay_price = res.data.data.order_total.pay_price
                        }
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

</style>
